import React from 'react';

import { RenderProp } from '../config/types';

const { isValidElement } = React;

const isFragment = (child: React.ReactNode) => {
  return child && isValidElement(child) && React.Fragment === child.type;
};

export const replaceElement = (
  element: React.ReactNode,
  replacement: React.ReactNode,
  props?: RenderProp,
): React.ReactNode => {
  if (!isValidElement(element)) return replacement;
  return React.cloneElement(
    element,
    'function' === typeof props ? props(element.props || {}) : props,
  );
};

export const cloneElement = (
  element: React.ReactNode,
  props?: RenderProp,
): React.ReactElement =>
  replaceElement(element, element, props) as React.ReactElement;

// props.children转成React.ReactElement[]格式
export const chilren2Array = (
  children: React.ReactNode,
): React.ReactElement[] => {
  let ret: React.ReactElement[] = [];
  React.Children.forEach(children, (child: any | any[]) => {
    if ([undefined, null].includes(child)) return;
    if (Array.isArray(child)) ret = ret.concat(chilren2Array(child));
    else if (isFragment(child) && child.props)
      ret = ret.concat(chilren2Array(child.props.children));
    else ret.push(child);
  });
  return ret;
};

// 验证是否全是数字
export const isNumber = (value: any) => {
  return !Number.isNaN(Number(value));
};
// 验证是否含有数字
export const isRegExpNumber = (value: any) => {
  const conterNumber = new RegExp('[0-9]');
  if (conterNumber.test(value)) {
    return true;
  } else {
    return false;
  }
};

// 验证是否全是特殊字符
export const isRegExp = (value: any) => {
  const flag = new RegExp(
    "[`~!@#$^&*()=|':;',.<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]",
  );

  // 判断 value 是否包含特殊字符
  if (flag.test(value)) {
    //  console.log('包含！')
    return true;
  } else {
    //  console.log('不包含！')
    return false;
  }
};

// 英文 日语 平假名 片假名
export const containsOnlyJapaneseCharactersAndAlphabet = (
  inputString: string,
) => {
  // const japaneseCharactersAndAlphabetRegex = /^[A-Za-z\u3040-\u309F\u30A0-\u30FF\uFF65-\uFF9F]+$/;
  const japaneseCharactersAndAlphabetRegex =
    /^[A-Za-z々ー\u30a1-\u30f6\u3041-\u3093\uFF00-\uFFFF\u4e00-\u9fa5]+$/;
  return japaneseCharactersAndAlphabetRegex.test(inputString);
};

export const containsOnlyJapaneseCharacters = (inputString: string) => {
  // const japaneseCharactersRegex = /^[\u3040-\u309F\u30A0-\u30FF\uFF65-\uFF9F]+$/;
  const katakanaRegex = /^[\u30A0-\u30FF]+$/;
  return katakanaRegex.test(inputString);
};

export const containsValidCollegePhrase = (inputString: string) => {
  const collegeNamePhrase = ['大学', '大學', 'カレッジ', '専門学校', '学院'];
  return (
    collegeNamePhrase.find((phrase) => inputString.includes(phrase)) ||
    inputString.toLowerCase().includes('college')
  );
};

/**
 * 根据当前 URL 的路径决定是否将其缓存到会话存储中。
 * 如果当前 URL 包含特殊路径，则将其缓存到会话存储；否则清除缓存。
 * @returns {boolean} - 如果当前 URL 包含特殊路径则返回 true，否则返回 false。
 */
export const getCurrentUrl = () => {
  try {
    const currentUrl = window.location.href;
    const currentPath = new URL(currentUrl).pathname; // 提取路径部分

    // 配置需要特殊处理的路径
    const specialPaths = ['/enterprise/', '/event/'];

    // 检查当前 URL 是否包含特殊路径
    for (const path of specialPaths) {
      if (currentPath.includes(path)) {
        // sessionStorage.setItem('currentUrl', currentPath); // 缓存路径到会话存储
        localStorage.setItem('currentUrl', currentPath);
        return true;
      }
    }
    // 如果当前 URL 不包含特殊路径，则清除之前的值
    // sessionStorage.removeItem('currentUrl');
    localStorage.removeItem('currentUrl');
    return false;

  } catch (error) {
    console.error('Error getting or caching current URL:', error);
    return false;
  }
};


