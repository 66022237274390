import { InstgramLink, LineLink, TikTokLink } from "@/data/constants";
import user from "@/data/user";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CaComponentProps } from "../../config/types";
import CameraSvg from "../../public/imgs/camera.svg";
import LineSvg from "../../public/imgs/line.svg";
import LogoSvg from "../../public/imgs/logo.svg";
import CloseSvg from "../../public/imgs/menu/close.svg";
import TikTokSvg from "../../public/imgs/tiktok.svg";
import CaButton from "../CaButton";
import { MenuList, MenuListMyPage } from "./configs";
import "./index.less";
import {getCurrentUrl} from "@/utils/_utils";

const CaMenuList = MenuList;

type props = CaComponentProps & {
  isShow?: any;
  isOffMenu?: any;
};

const CaMenu: React.FC<props> = ({ isShow, isOffMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [typeHeader, setTypeHeader] = useState(0); // 0是正常PC头部 1为MyPage特殊头部
  const isLogin = user.isLogin();
  useEffect(() => {
    const isUserHeader = location.pathname.indexOf("MyPage");
    if (
      isUserHeader > -1 ||
      location.pathname.indexOf("myPage") > -1 ||
      location.pathname.indexOf("mypage") > -1 ||
      location.pathname.indexOf("my-page") > -1
    ) {
      setTypeHeader(1);
    } else {
      setTypeHeader(0);
    }
  }, [location.pathname]);
  const offMenu = () => {
    isOffMenu(false);
  };
  const toPath = (items: string | URL | undefined) => {
    isOffMenu(false);
    // window.open(items,'_self')
    items && navigate(items);
  };

  const toLogin = () => {
    getCurrentUrl();
    navigate("/auth/login");
    offMenu();
  };

  const toRegister = () => {
    getCurrentUrl();
    navigate("/register");
    offMenu();
  };

  const toHome = () => {
    navigate("/");
    offMenu();
  };

  const toMyPage = () => {
    navigate("/myPage");
    offMenu();
  };

  const toLogout = () => {
    user.clearAccessToken();
    localStorage.removeItem('currentUrl');
    navigate("/auth/login");
    offMenu();
  };

  const openInstagram = () => {
    window.open(InstgramLink, "_blank");
  };

  const openTikTok = () => {
    window.open(TikTokLink, "_blank");
  };

  const openLine = () => {
    window.open(LineLink, "_blank");
  };

  return (
    <div>
      {isShow ? (
        <div className="ca-menu">
          <div className="ca-menu-header">
            <div className="ca-menu-header-left">
              <LogoSvg />
              <h2>女子キャリ就活</h2>
              {isShow}
            </div>
            <div onClick={offMenu} className="ca-menu-header-right">
              <CloseSvg />
            </div>
          </div>

          <ul className="menu-list">
            {typeHeader === 0
              ? CaMenuList.map((items) => (
                  <li
                    className="font-apparat"
                    onClick={() => {
                      toPath(items.link);
                    }}
                    key={items.link}
                  >
                    {items.title}
                  </li>
                ))
              : MenuListMyPage.map((items) => (
                  <li
                    onClick={() => {
                      toPath(items.link);
                    }}
                    key={items.link}
                  >
                    {items.title}
                  </li>
                ))}
          </ul>

          {typeHeader === 0 ? (
            isLogin ? (
              <div className="menu-button">
                <CaButton clickHandle={toMyPage} type="primary" size="middle">
                  マイページへ
                </CaButton>
                <CaButton size="middle" clickHandle={toLogout}>
                  ログアウト
                </CaButton>
              </div>
            ) : (
              <div className="menu-button">
                <CaButton clickHandle={toRegister} type="primary" size="middle">
                  無料会員登録
                </CaButton>
                <CaButton type="primary" size="middle" clickHandle={toLogin}>
                  ログイン
                </CaButton>
              </div>
            )
          ) : (
            <div className="menu-button" style={{ marginTop: "158px" }}>
              <CaButton type="primary" size="middle" clickHandle={toHome}>
                トップページに戻る
              </CaButton>
              <CaButton size="middle" clickHandle={toLogout}>
                ログアウト
              </CaButton>
            </div>
          )}

          <ul className="menu-footer">
            <li onClick={openInstagram}>
              <CameraSvg />
            </li>
            <li onClick={openTikTok}>
              <TikTokSvg />
            </li>
            <li onClick={openLine}>
              <LineSvg />
            </li>
          </ul>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CaMenu;
